<template>
    <div>
<!--        <Row>-->
<!--            <Col span="12">-->
<!--                <EchartLine id="one" width="90%" title="7日用户增长数据" titleCenter="left" :xAxis="xAxisData" :series="oneSeries" ></EchartLine>-->
<!--            </Col>-->
<!--            <Col span="12">-->
<!--                <EchartPie id="two" width="90%" title="用户统计"  :data="customerData"></EchartPie>-->
<!--            </Col>-->
<!--        </Row>-->
<!--        <Row>-->
<!--            <Col span="24">-->
<!--                 <EchartLine id="three" width="90%" title="订单数据" :xAxis="xAxisData" :series="seriesData" ></EchartLine>-->
<!--            </Col>-->
<!--        </Row>-->
      <div class="no-msg">
        <img src="../../assets/noMsg.png"/>
      </div>
    </div>
</template>
<script>
// import {EchartLine, EchartPie} from '@/components/eChart/index'
export default {
    // components:{
    //     EchartLine,
    //     EchartPie
    // },
    data(){
        return{
            oneSeries:[
                   {
                    name: '游客',
                    type: 'line',
                    stack: '总量',
                    data: [120, 132, 101, 134, 90, 230, 210]
                },
                {
                    name: '作者',
                    type: 'line',
                    stack: '总量',
                    data: [220, 182, 191, 234, 290, 330, 310]
                }
            ],
            xAxisData: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            seriesData: [{
                    type:'line',
                    data:[150, 230, 224, 218, 135, 147, 260]
                }],
            customerData:[
                    {value: 1048, name: '作者'},
                    {value: 735, name: '游客'}
            ]
        }
    },
    mounted(){
    },
    methods:{
    }
}
</script>
<style scoped>
.no-msg {
  width: 400px;
  height: 400px;
  margin: auto;
}
.no-msg img {
  width: 100%;
  height: 100%;
}

</style>